/* Design system palette declarations */ 

:root, .light, .light-theme {

  /* ------------ Green ------------- */
  --green-1: #fafefc;
  --green-2: #f2fcf7;
  --green-3: #e0f8ed;
  --green-4: #cdf3e1;
  --green-5: #b7ecd3;
  --green-6: #9ce2c2;
  --green-7: #73d3ab;
  --green-8: #1abf8c;
  --green-9: #12bc89;
  --green-10: #00b07e;
  --green-11: #008259;
  --green-12: #0a3d2b;

  --green-a1: #00cc6605;
  --green-a2: #00c5630d;
  --green-a3: #00c66b1f;
  --green-a4: #00c26632;
  --green-a5: #00bc6448;
  --green-a6: #00b56263;
  --green-a7: #00af668c;
  --green-a8: #00b87fe5;
  --green-a9: #00b780ed;
  --green-a10: #00b07e;
  --green-a11: #008259;
  --green-a12: #003522f5;

  --green-contrast: #fff;
  --green-surface: #effbf5cc;
  --green-indicator: #12bc89;
  --green-track: #12bc89;
  /* -------------------------------- */

  /* ------------ Blue -------------- */
  --blue-1: #fbfdff;
  --blue-2: #f4f9ff;
  --blue-3: #e9f3ff;
  --blue-4: #daecff;
  --blue-5: #c8e2ff;
  --blue-6: #b2d5ff;
  --blue-7: #97c3ff;
  --blue-8: #6fabfd;
  --blue-9: #1482ff;
  --blue-10: #0074f3;
  --blue-11: #006de8;
  --blue-12: #033269;

  --blue-a1: #0080ff04;
  --blue-a2: #0074ff0b;
  --blue-a3: #0074ff16;
  --blue-a4: #007dff25;
  --blue-a5: #0079ff37;
  --blue-a6: #0074ff4d;
  --blue-a7: #006cff68;
  --blue-a8: #006bfc90;
  --blue-a9: #0077ffeb;
  --blue-a10: #0074f3;
  --blue-a11: #006de8;
  --blue-a12: #003067fc;

  --blue-contrast: #fff;
  --blue-surface: #f1f8ffcc;
  --blue-indicator: #1482ff;
  --blue-track: #1482ff;
  /* -------------------------------- */

  /* ------------ Orange ------------ */
  --orange-1: #fefcfb;
  --orange-2: #fff6ed;
  --orange-3: #ffecda;
  --orange-4: #ffdcbc;
  --orange-5: #ffcea2;
  --orange-6: #ffbf84;
  --orange-7: #f8ad6e;
  --orange-8: #ed9547;
  --orange-9: #e88000;
  --orange-10: #d47900;
  --orange-11: #b16900;
  --orange-12: #55300e;

  --orange-a1: #c0400004;
  --orange-a2: #ff800012;
  --orange-a3: #ff7d0025;
  --orange-a4: #ff7a0043;
  --orange-a5: #ff79005d;
  --orange-a6: #ff7b017b;
  --orange-a7: #f36f0091;
  --orange-a8: #e66c00b8;
  --orange-a9: #e88000;
  --orange-a10: #d47900;
  --orange-a11: #b16900;
  --orange-a12: #4b2400f1;

  --orange-contrast: #fff;
  --orange-surface: #fff4e9cc;
  --orange-indicator: #e88000;
  --orange-track: #e88000;
  /* -------------------------------- */

  /* ------------- Red -------------- */
  --red-1: #fffcfc;
  --red-2: #fff7f7;
  --red-3: #ffeaea;
  --red-4: #ffdbdb;
  --red-5: #ffcdce;
  --red-6: #fdbdbe;
  --red-7: #f5aaab;
  --red-8: #ec8f92;
  --red-9: #ff5065;
  --red-10: #f1425a;
  --red-11: #d01840;
  --red-12: #68121f;

  --red-a1: #ff000003;
  --red-a2: #ff000008;
  --red-a3: #ff000015;
  --red-a4: #ff000024;
  --red-a5: #ff000632;
  --red-a6: #f8000442;
  --red-a7: #e1000355;
  --red-a8: #d4000770;
  --red-a9: #ff001faf;
  --red-a10: #ec0021bd;
  --red-a11: #cb002ce7;
  --red-a12: #5d000eed;

  --red-contrast: #fff;
  --red-surface: #fff5f5cc;
  --red-indicator: #ff5065;
  --red-track: #ff5065;
  /* -------------------------------- */

  /* ------------- Black ------------ */
  --black-1: #fcfcfd;
  --black-2: #f8f9f9;
  --black-3: #f0f0f1;
  --black-4: #e8e8e9;
  --black-5: #e0e1e2;
  --black-6: #d8d9da;
  --black-7: #cdcecf;
  --black-8: #babbbc;
  --black-9: #090a0c;
  --black-10: #252628;
  --black-11: #626365;
  --black-12: #1e1f21;

  --black-a1: #00005503;
  --black-a2: #00252507;
  --black-a3: #0000110f;
  --black-a4: #00000c17;
  --black-a5: #0009111f;
  --black-a6: #00070e27;
  --black-a7: #00060b32;
  --black-a8: #00040845;
  --black-a9: #000103f6;
  --black-a10: #000104da;
  --black-a11: #0002059d;
  --black-a12: #000103e1;

  --black-contrast: #fff;
  --black-surface: #f6f8f8cc;
  --black-indicator: #090a0c;
  --black-track: #090a0c;
  /* -------------------------------- */

  /* ------------- White ------------ */
  --white-1: #ffffff;
  --white-2: #f8f9f9;
  --white-3: #f0f0f1;
  --white-4: #e8e8e9;
  --white-5: #e0e1e2;
  --white-6: #d9d9da;
  --white-7: #cdcecf;
  --white-8: #bbbbbc;
  --white-9: #8b8c8e;
  --white-10: #7f8082;
  --white-11: #626365;
  --white-12: #1e1f21;

  --white-a1: #00005503;
  --white-a2: #00252507;
  --white-a3: #0000110f;
  --white-a4: #00000c17;
  --white-a5: #0009111f;
  --white-a6: #00000726;
  --white-a7: #00060b32;
  --white-a8: #00000444;
  --white-a9: #00030774;
  --white-a10: #00020680;
  --white-a11: #0002059d;
  --white-a12: #000103e1;

  --white-contrast: #fdfdfd;
  --white-surface: #f6f8f8cc;
  --white-indicator: #8b8c8e;
  --white-track: #8b8c8e;
  /* -------------------------------- */

  /* ----------- Gradients ---------- */
  --gradient-1: linear-gradient(180deg, #F7FAFC 0%, #F4EAEA 51%, #D1D9E3 100%);
  --gradient-2: linear-gradient(180deg, #F7FAFC 0%, #F2F2EB 80.7%, #FFF3D5 100%);
  --gradient-3: linear-gradient(180deg, #F7FAFC 0%, #DBE2E8 44.66%, #C5CFD3 70.5%, #E1D3C8 88%, #FEE6DB 100%);
  --gradient-4: linear-gradient(180deg, #F7FAFC 0%, #EDEEE9 44.66%, #E1E2DD 100%);
  --gradient-5: linear-gradient(180deg, #F7FAFC 0%, #F5ECF7 51%, #FFEBDF 100%);
  /* -------------------------------- */

}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {

      /* ------------ Green -------------- */
      --green-1: oklch(99.4% 0.006 164.9);
      --green-2: oklch(98.2% 0.012 164.9);
      --green-3: oklch(95.9% 0.029 164.9);
      --green-4: oklch(93.3% 0.047 164.9);
      --green-5: oklch(90% 0.064 164.9);
      --green-6: oklch(85.7% 0.083 164.9);
      --green-7: oklch(79.8% 0.109 164.9);
      --green-8: oklch(71.5% 0.146 164.9);
      --green-9: oklch(70.6% 0.146 164.9);
      --green-10: oklch(66.9% 0.146 164.9);
      --green-11: oklch(52.9% 0.146 164.9);
      --green-12: oklch(32.2% 0.061 164.9);

      --green-a1: color(display-p3 0.024 0.757 0.267 / 0.016);
      --green-a2: color(display-p3 0.02 0.733 0.29 / 0.044);
      --green-a3: color(display-p3 0.004 0.694 0.349 / 0.102);
      --green-a4: color(display-p3 0.004 0.702 0.329 / 0.169);
      --green-a5: color(display-p3 0.004 0.659 0.314 / 0.24);
      --green-a6: color(display-p3 0.004 0.635 0.302 / 0.33);
      --green-a7: color(display-p3 0.004 0.604 0.302 / 0.455);
      --green-a8: color(display-p3 0 0.596 0.325 / 0.651);
      --green-a9: color(display-p3 0 0.584 0.318 / 0.659);
      --green-a10: color(display-p3 0 0.537 0.294 / 0.695);
      --green-a11: color(display-p3 0 0.361 0.18 / 0.781);
      --green-a12: color(display-p3 0 0.145 0.075 / 0.895);

      --green-surface: color(display-p3 0.945 0.984 0.961 / 0.8);
      --green-indicator: oklch(70.6% 0.146 164.9);
      --green-track: oklch(70.6% 0.146 164.9);
      /* -------------------------------- */

      /* ------------ Blue -------------- */
      --blue-1: oklch(99.3% 0.004 256.5);
      --blue-2: oklch(98.2% 0.011 256.5);
      --blue-3: oklch(96% 0.022 256.5);
      --blue-4: oklch(93.7% 0.041 256.5);
      --blue-5: oklch(90.6% 0.06 256.5);
      --blue-6: oklch(86.4% 0.077 256.5);
      --blue-7: oklch(80.9% 0.099 256.5);
      --blue-8: oklch(73.5% 0.135 256.5);
      --blue-9: oklch(62.1% 0.207 256.5);
      --blue-10: oklch(57.9% 0.211 256.5);
      --blue-11: oklch(55.5% 0.207 256.5);
      --blue-12: oklch(32.3% 0.108 256.5);

      --blue-a1: color(display-p3 0.024 0.514 1 / 0.016);
      --blue-a2: color(display-p3 0.024 0.416 0.906 / 0.04);
      --blue-a3: color(display-p3 0.008 0.408 0.953 / 0.079);
      --blue-a4: color(display-p3 0.008 0.416 0.945 / 0.134);
      --blue-a5: color(display-p3 0.008 0.404 0.941 / 0.197);
      --blue-a6: color(display-p3 0.004 0.388 0.945 / 0.275);
      --blue-a7: color(display-p3 0.004 0.361 0.949 / 0.373);
      --blue-a8: color(display-p3 0 0.345 0.941 / 0.514);
      --blue-a9: color(display-p3 0 0.349 0.961 / 0.765);
      --blue-a10: color(display-p3 0 0.318 0.902 / 0.808);
      --blue-a11: color(display-p3 0 0.294 0.851 / 0.82);
      --blue-a12: color(display-p3 0 0.125 0.349 / 0.926);

      --blue-surface: color(display-p3 0.953 0.973 1 / 0.8);
      --blue-indicator: oklch(62.1% 0.207 256.5);
      --blue-track: oklch(62.1% 0.207 256.5);
      /* -------------------------------- */

      /* ------------ Orange ------------ */
      --orange-1: oklch(99.2% 0.003 59.73);
      --orange-2: oklch(97.9% 0.017 59.73);
      --orange-3: oklch(95.8% 0.041 59.73);
      --orange-4: oklch(92.2% 0.087 59.73);
      --orange-5: oklch(89.2% 0.114 59.73);
      --orange-6: oklch(85.9% 0.116 59.73);
      --orange-7: oklch(80.7% 0.119 59.73);
      --orange-8: oklch(74.6% 0.14 59.73);
      --orange-9: oklch(70.1% 0.179 59.73);
      --orange-10: oklch(66.3% 0.179 59.73);
      --orange-11: oklch(59% 0.179 59.73);
      --orange-12: oklch(35% 0.071 59.73);

      --orange-a1: color(display-p3 0.757 0.267 0.024 / 0.016);
      --orange-a2: color(display-p3 0.886 0.475 0.008 / 0.067);
      --orange-a3: color(display-p3 0.918 0.49 0.008 / 0.138);
      --orange-a4: color(display-p3 0.906 0.463 0.004 / 0.248);
      --orange-a5: color(display-p3 0.91 0.463 0.004 / 0.342);
      --orange-a6: color(display-p3 0.914 0.467 0.004 / 0.448);
      --orange-a7: color(display-p3 0.867 0.416 0 / 0.53);
      --orange-a8: color(display-p3 0.824 0.396 0 / 0.663);
      --orange-a9: color(display-p3 0.82 0.412 0 / 0.812);
      --orange-a10: color(display-p3 0.741 0.384 0 / 0.828);
      --orange-a11: color(display-p3 0.6 0.329 0 / 0.859);
      --orange-a12: color(display-p3 0.251 0.125 0 / 0.918);
    
      --orange-surface: color(display-p3 1 0.957 0.918 / 0.8);
      --orange-indicator: oklch(70.1% 0.179 59.73);
      --orange-track: oklch(70.1% 0.179 59.73);
      /* -------------------------------- */

      /* ------------- Red -------------- */
      --red-1: oklch(99.4% 0.003 18.08);
      --red-2: oklch(98.3% 0.009 18.08);
      --red-3: oklch(95.4% 0.024 18.08);
      --red-4: oklch(92.8% 0.047 18.08);
      --red-5: oklch(89.6% 0.061 18.08);
      --red-6: oklch(85.8% 0.074 18.08);
      --red-7: oklch(80.9% 0.089 18.08);
      --red-8: oklch(74.8% 0.112 18.08);
      --red-9: oklch(68% 0.21 18.08);
      --red-10: oklch(64.1% 0.21 18.08);
      --red-11: oklch(55.1% 0.21 18.08);
      --red-12: oklch(34.1% 0.118 18.08);

      --red-a1: color(display-p3 0.675 0.024 0.024 / 0.012);
      --red-a2: color(display-p3 0.878 0.02 0.02 / 0.032);
      --red-a3: color(display-p3 0.812 0.059 0.008 / 0.083);
      --red-a4: color(display-p3 0.831 0.008 0.008 / 0.138);
      --red-a5: color(display-p3 0.835 0.008 0.008 / 0.189);
      --red-a6: color(display-p3 0.816 0.02 0.004 / 0.251);
      --red-a7: color(display-p3 0.745 0.004 0.004 / 0.322);
      --red-a8: color(display-p3 0.706 0.004 0.012 / 0.424);
      --red-a9: color(display-p3 0.882 0 0.071 / 0.632);
      --red-a10: color(display-p3 0.812 0 0.075 / 0.683);
      --red-a11: color(display-p3 0.69 0 0.098 / 0.812);
      --red-a12: color(display-p3 0.302 0 0.031 / 0.899);

      --red-surface: color(display-p3 0.996 0.961 0.961 / 0.8);
      --red-indicator: oklch(68% 0.21 18.08);
      --red-track: oklch(68% 0.21 18.08);
      /* -------------------------------- */

      /* ------------- Black ------------ */
      --black-1: oklch(99.2% 0.001 263.8);
      --black-2: oklch(98.1% 0.001 263.8);
      --black-3: oklch(95.5% 0.001 263.8);
      --black-4: oklch(93.1% 0.001 263.8);
      --black-5: oklch(90.9% 0.002 263.8);
      --black-6: oklch(88.5% 0.002 263.8);
      --black-7: oklch(85.1% 0.002 263.8);
      --black-8: oklch(79.2% 0.002 263.8);
      --black-9: oklch(14.5% 0.005 263.8);
      --black-10: oklch(26.7% 0.005 263.8);
      --black-11: oklch(50.1% 0.003 263.8);
      --black-12: oklch(24% 0.005 263.8);

      --black-a1: color(display-p3 0.024 0.024 0.349 / 0.012);
      --black-a2: color(display-p3 0.024 0.161 0.161 / 0.028);
      --black-a3: color(display-p3 0.004 0.004 0.071 / 0.059);
      --black-a4: color(display-p3 0.012 0.012 0.055 / 0.091);
      --black-a5: color(display-p3 0.004 0.039 0.071 / 0.122);
      --black-a6: color(display-p3 0.004 0.027 0.055 / 0.153);
      --black-a7: color(display-p3 0.008 0.027 0.047 / 0.197);
      --black-a8: color(display-p3 0.004 0.02 0.031 / 0.271);
      --black-a9: color(display-p3 0 0.004 0.012 / 0.965);
      --black-a10: color(display-p3 0 0.004 0.016 / 0.855);
      --black-a11: color(display-p3 0 0.008 0.02 / 0.616);
      --black-a12: color(display-p3 0 0.004 0.012 / 0.883);

      --black-surface: color(display-p3 0.965 0.973 0.973 / 0.8);
      --black-indicator: oklch(14.5% 0.005 263.8);
      --black-track: oklch(14.5% 0.005 263.8);
      /* -------------------------------- */

      /* ------------- White ------------ */
      --white-1: oklch(99.2% 0.001 263.8);
      --white-2: oklch(98.1% 0.001 263.8);
      --white-3: oklch(95.5% 0.001 263.8);
      --white-4: oklch(93.1% 0.001 263.8);
      --white-5: oklch(90.9% 0.001 263.8);
      --white-6: oklch(88.5% 0.001 263.8);
      --white-7: oklch(85.1% 0.002 263.8);
      --white-8: oklch(79.3% 0.002 263.8);
      --white-9: oklch(64% 0.004 263.8);
      --white-10: oklch(59.9% 0.004 263.8);
      --white-11: oklch(50.1% 0.003 263.8);
      --white-12: oklch(24% 0.004 263.8);

      --white-a1: color(display-p3 0.024 0.024 0.349 / 0.012);
      --white-a2: color(display-p3 0.024 0.161 0.161 / 0.028);
      --white-a3: color(display-p3 0.004 0.004 0.071 / 0.059);
      --white-a4: color(display-p3 0.012 0.012 0.055 / 0.091);
      --white-a5: color(display-p3 0.004 0.039 0.071 / 0.122);
      --white-a6: color(display-p3 0.008 0.008 0.035 / 0.15);
      --white-a7: color(display-p3 0.008 0.027 0.047 / 0.197);
      --white-a8: color(display-p3 0.004 0.004 0.02 / 0.267);
      --white-a9: color(display-p3 0.004 0.012 0.027 / 0.455);
      --white-a10: color(display-p3 0 0.008 0.024 / 0.502);
      --white-a11: color(display-p3 0 0.008 0.02 / 0.616);
      --white-a12: color(display-p3 0 0.004 0.012 / 0.883);

      --white-surface: color(display-p3 0.965 0.973 0.973 / 0.8);
      --white-indicator: oklch(64% 0.004 263.8);
      --white-track: oklch(64% 0.004 263.8); 
      /* -------------------------------- */

      /* ----------- Gradients ---------- */
      --gradient-1: linear-gradient(180deg, color(display-p3 0.973 0.980 0.988) 0%, color(display-p3 0.949 0.918 0.918) 51%, color(display-p3 0.824 0.851 0.886) 100%);
      --gradient-2: linear-gradient(180deg, color(display-p3 0.973 0.980 0.988) 0%, color(display-p3 0.949 0.949 0.922) 80.7%, color(display-p3 0.996 0.957 0.847) 100%);
      --gradient-3: linear-gradient(180deg, color(display-p3 0.973 0.980 0.988) 0%, color(display-p3 0.863 0.886 0.906) 44.66%, color(display-p3 0.780 0.808 0.827) 70.5%, color(display-p3 0.875 0.827 0.788) 88%, color(display-p3 0.980 0.906 0.863) 100%);
      --gradient-4: linear-gradient(180deg, color(display-p3 0.973 0.980 0.988) 0%, color(display-p3 0.929 0.933 0.914) 44.66%, color(display-p3 0.882 0.886 0.867) 100%);
      --gradient-5: linear-gradient(180deg, color(display-p3 0.973 0.980 0.988) 0%, color(display-p3 0.953 0.925 0.965) 51%, color(display-p3 1.000 0.925 0.882) 100%);
      /* -------------------------------- */
      
    }
  }
}


